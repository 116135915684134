<div class="grid-x" id="blue-playlist-container">
  <div class="large-10 medium-12 small-11 large-centered medium-centered small-centered cell" id="amplitude-player">
    <div class="grid-x">
      <div class="large-6 medium-6 small-12 cell" id="amplitude-left">
        
        <picture>
          <source srcset="./assets/img/goodbye_my_friend-retirement_party_jgc7eo_c_scale,w_400.webp" type="image/webp" media="(max-width: 400px)">
          <source srcset="./assets/img/goodbye_my_friend-retirement_party_jgc7eo_c_scale,w_830.webp" type="image/webp">
          <source srcset="./assets/img/goodbye_my_friend-retirement_party.jpg" type="image/jpeg"> 
          <img src="./assets/img/goodbye_my_friend-retirement_party.jpg" alt="Retirement Party album cover">
        </picture>

        <div id="player-left-bottom">
          <div id="time-container">
            <span class="current-time">
              <span class="amplitude-current-minutes" ></span>:<span class="amplitude-current-seconds"></span>
            </span>
            <div id="progress-container">
              <input type="range" class="amplitude-song-slider"/>
              <progress id="song-played-progress" class="amplitude-song-played-progress"></progress>
              <progress id="song-buffered-progress" class="amplitude-buffered-progress" value="0"></progress>
            </div>
            <span class="duration">
              <span class="amplitude-duration-minutes"></span>:<span class="amplitude-duration-seconds"></span>
            </span>
          </div>

          <div id="control-container">
            <div id="repeat-container">
              
            </div>

            <div id="central-control-container">
              <div id="central-controls">
                <div matRipple class="amplitude-prev" id="previous"></div>
                <div matRipple class="amplitude-play-pause" id="play-pause"></div>
                <div matRipple class="amplitude-next" id="next"></div>
              </div>
            </div>

            <div id="volume-container">
              <div class="volume-controls">
                <div class="amplitude-mute amplitude-not-muted"></div>
                <input type="range" class="amplitude-volume-slider"/>
                <div class="ms-range-fix"></div>
              </div>
              <div class="amplitude-shuffle amplitude-shuffle-off" id="shuffle-right"></div>
            </div>
          </div>



          <div id="meta-container">
            <span data-amplitude-song-info="name" class="song-name"></span>

            <div class="song-artist-album">
              <span data-amplitude-song-info="artist"></span>
              <span data-amplitude-song-info="album"></span>
            </div>
          </div>
        </div>
      </div>

      <div class="large-6 medium-6 small-12 cell" id="amplitude-right">


        <div matRipple 
          class="song amplitude-song-container amplitude-play-pause" 
          data-amplitude-song-index="0">
          <div class="song-now-playing-icon-container">
            <div class="play-button-container">

            </div>
            <img class="now-playing" src="./assets/img/now-playing.svg"/>
          </div>
          <div class="song-meta-data">
            <span class="song-title">{{ playlist?.songs[0]?.name }}</span>
            <span class="song-artist">{{ playlist?.songs[0]?.artist }}</span>
          </div>
          <span class="song-duration">{{ playlist?.songs[0]?.duration }}</span>
        </div>

        <div matRipple 
          class="song amplitude-song-container amplitude-play-pause" 
          data-amplitude-song-index="1">
          <div class="song-now-playing-icon-container">
            <div class="play-button-container">

            </div>
            <img class="now-playing" src="./assets/img/now-playing.svg"/>
          </div>
          <div class="song-meta-data">
            <span class="song-title">{{ playlist?.songs[1]?.name }}</span>
            <span class="song-artist">{{ playlist?.songs[1]?.artist }}</span>
          </div>
          <span class="song-duration">{{ playlist?.songs[1]?.duration }}</span>
        </div>

        <div matRipple 
          class="song amplitude-song-container amplitude-play-pause" 
          data-amplitude-song-index="2">
          <div class="song-now-playing-icon-container">
            <div class="play-button-container">

            </div>
            <img class="now-playing" src="./assets/img/now-playing.svg"/>
          </div>
          <div class="song-meta-data">
            <span class="song-title">{{ playlist?.songs[2]?.name }}</span>
            <span class="song-artist">{{ playlist?.songs[2]?.artist }}</span>
          </div>
          <span class="song-duration">{{ playlist?.songs[2]?.duration }}</span>
        </div>

      </div>
    </div>
  </div>
</div>
