<div class="head--container">
  <img 
    class="head--specailtysongs-logo" 
    src="./assets/img/logo/specialty_songs.svg"
    alt="Specialty Songs logo, cursive font">
</div>

<div 
  class="container">
  <h2>The Perfect Song for Your Retirement Party Slideshow</h2>

  <p 
    class="main">
    Are you searching for a song that will honor a loved one leaving the workplace? <i>Goodbye My Friend</i> never fails to impact an audience with an atmosphere of warmth and respect. 
    This retirement song has been a hit in many retirement party playlists and slideshows. <br><br>
  </p>

  <div class="links">
    <a matRipple 
      href="https://open.spotify.com/artist/6BPxtWkvCIngnDE3IGHbL7?si=RrthqZEISGKu66UMAiUBLQ"
      target="_blank">
      <img 
        class="stores-svg" 
        src="assets/img/spotify.svg"
        alt="Open Goodbye My Friend in Spotify">
    </a>
    <a matRipple 
      href="https://music.apple.com/us/album/retirement-party-single/1465655874?uo=4&app=music&at=1001lry3&ct=dashboard"
      target="_blank">
      <img 
        class="stores-svg" 
        src="assets/img/apple_music.svg"
        alt="Open Goodbye My Friend in Apple Music">
    </a>
    <a matRipple 
      href="https://music.apple.com/us/album/retirement-party-single/1465655874?uo=4&app=music&at=1001lry3&ct=dashboard&app=itunes&at=1001lry3&ct=dashboard"
      target="_blank">
      <img 
        class="stores-svg" 
        src="assets/img/itunes.svg"
        alt="Open Goodbye My Friend in iTunes">
    </a>
    <a matRipple 
      href="https://play.google.com/store/music/album/Lance_Susie_Retirement_Party?id=Bfwyl4yi27qh2n5mwmoohcpqq6m&hl=en_US"
      target="_blank">
      <img 
        class="stores-svg" 
        src="assets/img/google_play.svg"
        alt="Open Goodbye My Friend in Google Play Music">
    </a>
    <a matRipple 
      href="https://www.amazon.com/dp/B07SBY1MP7/ref=cm_sw_em_r_mt_dp_U_v9W8CbPBNMN37"
      target="_blank">
      <img 
        class="stores-svg" 
        src="assets/img/amazon.svg"
        alt="Open Goodbye My Friend in Amazon">
    </a>
  </div>
  
  <br>  
  <music-player></music-player>

  <br>
  
  <p class="main">
    Independent singer-songwriters from the Pacific Northwest, Lance and Susie Krauter create contemporary 
    music with a country flair, with thoughtful lyrics inspired by their faith. Their heartwarming tune, 
    Goodbye My Friend, touched the hearts of many with Susie's lovely vocals accompanied by Lance's driving 
    acoustic guitar and lyrics that write a story that evokes an emotional response. 
  </p>

  <picture>
    <source srcset="./assets/img/lance_and_susie_taylor_guitar_pxwnmr_c_scale,w_400.webp" type="image/webp" media="(max-width: 400px)">
    <source srcset="./assets/img/lance_and_susie_taylor_guitar_pxwnmr_c_scale,w_1419.webp" type="image/webp">
    <source srcset="./assets/img/lance_and_susie_taylor_guitar.jpg" type="image/jpeg"> 
    <img src="./assets/img/lance_and_susie_taylor_guitar.jpg" alt="Lance and Susie on a beach. Lance is holding his Taylor Guitar.">
  </picture>

  <mat-tab-group>
    <mat-tab label="Default (Version For Her)">
      <div class="lyrics">
        <h2 class="title">
          Goodbye My Friend
        </h2>

        <p class="copyright">
          &#169; Susie Krauter 2003
        </p>

        <p class="verse">
          Oh the winds of change are blowin'<br>
          Sometimes it seems that all good things must come to an end<br>
          We all knew this day was coming<br>
          But ya know it's just not easy to say goodbye to a friend<br>
          This place will never be the same without you in it<br>
          No one to tell us like it is and like it isn't
        </p>

        <p class="chorus">
          The way you do, and seasons change<br>
          The years go by, and lives we share<br>
          And now the time has come to say goodbye my friend<br>
          What will we do <span class="diff">without the queen</span><br>
          It's been so good, but now the time has come<br>
          To say goodbye, goodbye my friend
        </p>

        <p class="verse">
          Oh you run a real' tight ship, you're a mover and a shaker<br>
          And that you care shows right on through<br>
          You are loved and respected, with hearts of admiration<br>
          We want you to know, we don't want you to go<br>
          This place will never be the same without you in it<br>
          No one to tell us like it is and like it isn't
        </p>

        <p class="chorus">
          The way you do, and seasons change<br>
          The years go by, and lives we share<br>
          And now the time has come to say goodbye my friend<br>
          What will we do <span class="diff">without the queen</span><br>
          It's been so good, but now the time has come<br>
          To say goodbye, goodbye my friend
        </p>

        <p class="bridge">
          We know you really won't be that far away<br>
          But we'll be missin' you, no one can replace
        </p>

        <p class="chorus">
          But <span class="diff">there she goes</span>, and seasons change<br>
          The years go by, and lives we share<br>
          And now the time has come to say goodbye my friend<br>
          What will we do without the Queen<br>
          It's been so good, but now the time has come <br>
          To say goodbye, goodbye my friend<br>
          We don't want to say, goodbye my friend
        </p>
      </div>
    </mat-tab>
    <mat-tab label="Version For Anyone">
      <div class="lyrics">
        <h2 class="title">
          Goodbye My Friend
        </h2>

        <p class="copyright">
          &#169; Susie Krauter 2003
        </p>

        <p class="verse">
          Oh the winds of change are blowin'<br>
          Sometimes it feels like all good things must come to an end<br>
          We all knew this day was coming<br>
          But ya know it's just not easy to say goodbye to a friend<br>
          This place will never be the same without you in it<br>
          No one to tell us like it is and like it isn't
        </p>
        
        <p class="chorus">
          The way you do, and seasons change<br>
          The years go by, and lives we share<br>
          And now the time has come to say goodbye my friend<br>
          What will we do <span class="diff">without you</span><br>
          It's been so good, but now the time has come<br>
          To say goodbye, goodbye my friend
        </p>
        
        <p class="verse">
          Oh you run a real' tight ship, you're a mover and a shaker<br>
          And that you care shows right on through<br>
          You are loved and respected, with hearts of admiration<br>
          We want you to know, we don't want you to go<br>
          This place will never be the same without you in it<br>
          No one to tell us like it is and like it isn't
        </p>
        
        <p class="chorus">
          The way you do, and seasons change<br>
          The years go by, and lives we share<br>
          And now the time has come to say goodbye my friend<br>
          What will we do <span class="diff">without you</span><br>
          It's been so good, but now the time has come<br>
          To say goodbye, goodbye my friend
        </p>
        
        <p class="bridge">
          We know you're really not that far away<br>
          But we'll be missin' you, no one can replace
        </p>
        
        <p class="chorus">
          But <span class="diff">there you go</span>, and seasons change<br>
          The years go by, and lives we share<br>
          And now the time has come to say goodbye my friend<br>
          What will we do without you<br>
          It's been so good, but now the time has come <br>
          To say goodbye, goodbye my friend<br>
          We don't want to say, goodbye my friend
        </p>
      </div>
    </mat-tab>
  </mat-tab-group>

  <h2>Show your support with social media!</h2>

  <div class="social-media">
    <div 
      class="facebook fb-like" 
      data-href="https://specialtysongs.com" 
      data-width="200" 
      data-layout="button_count" 
      data-action="like" 
      data-size="large" 
      data-show-faces="true" 
      data-share="true"></div>
    <iframe 
      class="spotify" 
      src="https://open.spotify.com/follow/1/?uri=spotify:artist:6BPxtWkvCIngnDE3IGHbL7&size=detail&theme=light" 
      width="201" 
      height="56" 
      scrolling="no" 
      frameborder="0" 
      style="border:none; overflow:hidden;" 
      allowtransparency="true">
    </iframe>
    <div 
      class="youtube">
      <div 
        class="g-ytsubscribe" 
        data-channelid="UCtXvNizfK_cR0V-19TKj1UQ" 
        data-layout="full" 
        data-count="default">
      </div>
    </div>
  </div>

  <p class="main">
    Check us out on 
    <a 
      href="https://open.spotify.com/artist/6BPxtWkvCIngnDE3IGHbL7?si=RrthqZEISGKu66UMAiUBLQ"
      target="_blank">
      Spotify
    </a>, 
    <a 
      href="https://music.apple.com/us/album/retirement-party-single/1465655874?uo=4&app=music&at=1001lry3&ct=dashboard"
      target="_blank">
      Apple Music
    </a>, 
    <a 
      href="https://music.apple.com/us/album/retirement-party-single/1465655874?uo=4&app=music&at=1001lry3&ct=dashboard&app=itunes&at=1001lry3&ct=dashboard"
      target="_blank">
      iTunes
    </a>, 
    <a 
      href="https://play.google.com/store/music/album/Lance_Susie_Retirement_Party?id=Bfwyl4yi27qh2n5mwmoohcpqq6m&hl=en_US"
      target="_blank">
      Google Play
    </a> or 
    <a 
      href="https://www.amazon.com/dp/B07SBY1MP7/ref=cm_sw_em_r_mt_dp_U_v9W8CbPBNMN37"
      target="_blank">
      Amazon
    </a> to get the full song!
  </p>
  
  <p class="main">
    &#169; Susie Krauter {{ year | date: "yyyy" }}
  </p>
</div>
