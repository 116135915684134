import { Component, OnInit } from '@angular/core';
import * as Amplitude from 'amplitudejs'

@Component({
  selector: 'music-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.sass']
})
export class PlayerComponent implements OnInit {

  constructor() { }

  playlist: any

  ngOnInit() {
    this.playlist = {
      "songs": [
        {
          "name": "Goodbye My Friend [Sample]",
          "artist": "Lance & Susie",
          "album": "Welcome to Retirement",
          "url": "./assets/mp3/goodbye_my_friend_clip.mp3",
          "duration": "1:36",
        },
        {
          "name": "Goodbye My Frien (Version for Anyone) [Sample]",
          "artist": "Lance & Susie",
          "album": "Welcome to Retirement",
          "url": "./assets/mp3/goodbye_my_friend_anyone_clip.mp3",
          "duration": "1:36",
        },
        {
          "name": "Goodbye My Friend (Instrumental) [Sample]",
          "artist": "Lance & Susie",
          "album": "Welcome to Retirement",
          "url": "./assets/mp3/goodbye_my_friend_instrumental_clip.mp3",
          "duration": "1:36",
        },
      ]
    }

    Amplitude.init(this.playlist);
    // setTimeout(() => {
    //   Amplitude.playSongAtIndex(0);
    // }, 2000);
  }

}
