import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { PlayerComponent } from '@components/player';
import { MainComponent } from '@components/main';

import { CoreModule } from '@app/core';

@NgModule({
  declarations: [
    AppComponent,
    PlayerComponent,
    MainComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
